import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { isIosWebView } from "./util";
import { UpdateUserGeoLocationRequest } from "../model/requests";
import { UserService } from "./api/user.service";

@Injectable()
export class GeoLocationService {
    private readonly LOCAL_STORAGE_KEY = "cachedGeoLocation";

    constructor(private userService: UserService) {}

    /**
     * Retrieves the cached geolocation from local storage.
     */
    private getCachedPosition(): GeolocationPosition | null {
        const cached = localStorage.getItem(this.LOCAL_STORAGE_KEY);
        if (cached) {
            try {
                // The stored value is a plain object with the same shape as GeolocationPosition.
                return JSON.parse(cached) as GeolocationPosition;
            } catch (e) {
                console.error("Error parsing cached geolocation", e);
                return null;
            }
        }
        return null;
    }

    /**
     * Stores a simplified geolocation position in local storage.
     */
    private setCachedPosition(position: GeolocationPosition): void {
        const cachedPosition = {
            coords: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                accuracy: position.coords.accuracy,
                altitude: position.coords.altitude,
                altitudeAccuracy: position.coords.altitudeAccuracy,
                heading: position.coords.heading,
                speed: position.coords.speed,
            },
            timestamp: position.timestamp,
        };
        localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(cachedPosition));
    }

    /**
     * Returns an Observable that emits a geolocation.
     * If a cached position exists and the fresh request takes longer than 500ms,
     * it emits the cached value, but the fresh geolocation continues in the background
     * to update local storage and the server.
     */
    getGeoLocation(): Observable<GeolocationPosition> {
        return new Observable((observer) => {
            if (isIosWebView()) {
                observer.error("Geolocation is not supported in iOS WebView.");
                return;
            }

            if (!navigator.geolocation) {
                observer.error("Geolocation is not supported by this browser.");
                return;
            }

            let hasEmitted = false;
            let fallbackTimer: ReturnType<typeof setTimeout> | null = null;
            const cachedPosition = this.getCachedPosition();

            const successCallback = (position: GeolocationPosition) => {
                // Clear the fallback timer if it’s still pending.
                if (fallbackTimer) {
                    clearTimeout(fallbackTimer);
                }

                // Update the local storage cache.
                this.setCachedPosition(position);

                // Send the new geolocation to the server.
                const request: UpdateUserGeoLocationRequest = {
                    coordinates: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    },
                };
                this.userService.updateUserGeoLocation(request).subscribe();

                // Emit the fresh position if we haven’t already emitted one.
                if (!hasEmitted) {
                    hasEmitted = true;
                    observer.next(position);
                    observer.complete();
                }
            };

            const errorCallback = (error: GeolocationPositionError) => {
                if (fallbackTimer) {
                    clearTimeout(fallbackTimer);
                }
                if (!hasEmitted) {
                    observer.error(error);
                }
            };

            // If a cached value exists, set a fallback timer.
            if (cachedPosition) {
                fallbackTimer = setTimeout(() => {
                    if (!hasEmitted) {
                        hasEmitted = true;
                        observer.next(cachedPosition);
                        observer.complete();
                    }
                    // Note: The fresh geolocation request will still update the cache and server.
                }, 500);
            }

            // Always start the fresh geolocation request.
            navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
        });
    }
}
